import React, { Component } from 'react';

const TableContext = React.createContext();
const TableProvider = TableContext.Provider;
const TableConsumer = TableContext.Consumer;

class RemoteTableContext extends Component {
	state = {
		table: true,
		data: []
	};

	setData = (data) => {
		this.setState({ data });
	};

	render() {
		const { setData } = this;

		return (
			<TableProvider
				value={{
					...this.state,
					setData
				}}
			>
				{this.props.children}
			</TableProvider>
		);
	}
}

export { TableProvider, TableConsumer, TableContext, RemoteTableContext };
